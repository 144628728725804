$primary-color: rgb(237, 181, 14);

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;

    @media (max-width: 768px) {
        font-size: 12px;
    }

    &::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #434343;
        width: 100%;
        top: 20px;
        left: -50%;
        z-index: 2;
    }

    &::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 2;
    }

    &:first-child::before {
        content: none;
    }

    &:last-child::after {
        content: none;
    }

    &.active {
        font-weight: bold;

        .step-counter {
            background-color: #252626;
          outline: 2px solid $primary-color;
          color: $primary-color;
        }
    }

    &.completed .step-counter {
        background-color: $primary-color;
        color: #000;
        height: 40px;
        width: 40px;
        background-size: auto;
        background-image: url('/app/assets/images/icon-black-check-only.svg');
        background-position: center;
        background-repeat: no-repeat;

        span {
            display: none;
        }
    }

    .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #434343;
        margin-bottom: 14px;
    }
}
