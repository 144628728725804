:root {
    --color-primary-100: #ffcf52;
    --color-primary: #daa20f;
    --special-btn-size: 4.5em;
    --trading-card-width: 16rem;
}

@import "webfont";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "animate.css";
@import "stepper";
@import "pikaday/css/pikaday";

body {
    font-size: 100%;
}

@layer base {
    * {
        @apply box-border;
    }
    h1 {
        @apply text-4xl font-display font-semibold;
    }
    h2 {
        @apply text-2xl font-display font-semibold;
    }
    h3 {
        @apply text-xl font-display;
    }

    .special-btn__container {
        @apply overflow-hidden inline-flex items-center relative pr-12;
        transition: transform ease-in-out 0.3s;
        &:hover {
            transform: translate(1em);
            .special-btn {
                @apply to-primary-100;

                & + .special-btn__arrow {
                    background-color: var(--color-primary-100);
                }
            }
        }
    }

    .special-btn {
        position: relative;
        z-index: 1;
        @apply border-t border-b flex items-center uppercase h-full font-semibold text-black bg-gradient-to-r from-primary-100 to-primary pl-4 py-1;
        border-image-source: linear-gradient(
            to right,
            var(--tw-gradient-stops)
        );
        border-image-width: 1;
        border-image-slice: 1;
    }

    .special-btn__arrow {
        width: var(--special-btn-size);
        height: var(--special-btn-size);
        transform: rotate(45deg);
        background-color: var(--color-primary);
        position: absolute;
        right: 2.25rem;
    }

    .gradient-header {
        background: rgb(238, 238, 238);
        background: linear-gradient(
            315deg,
            rgba(238, 238, 238, 1) 0%,
            rgba(246, 246, 246, 1) 24%,
            rgba(246, 246, 246, 1) 40%,
            rgba(246, 246, 246, 1) 42%,
            rgba(218, 218, 218, 1) 50%,
            rgba(238, 238, 238, 1) 75%,
            rgba(255, 255, 255, 1) 75%,
            rgba(238, 238, 238, 1) 94%,
            rgba(238, 238, 238, 1) 100%
        );
        background-repeat: no-repeat;
    }

    // Must have both CardImage component for front and back to make flip work

    .trophyRoom-card {
        transition: all 0.5s ease-in-out;
        transform-origin: center;
        transform-style: preserve-3d;
        background-color: white;
        transform: perspective(100vw) rotateY(0deg);

        .front,
        .back {
            backface-visibility: hidden;
            -moz-backface-visibility: hidden;
        }
        .back {
            transform: rotateY(180deg);
        }
        &.flip {
            /*rotate card*/
            transform: perspective(100vw) rotateY(180deg);
        }
    }
    .trading-card {
        @apply bg-white rounded border-8 border-white flex-shrink-0 select-none bg-contain bg-center bg-no-repeat;
        width: var(--trading-card-width);
        height: calc(var(--trading-card-width) / 0.714);
        @media screen and (max-width: 768px) {
            --trading-card-width: 10rem;
        }
    }

    #tradingCardCarousel {
        height: calc((var(--trading-card-width) / 0.714) * 1.4);
        .trading-card {
            @apply absolute shadow-md;
            transition: transform 0.2s ease-in-out;
            z-index: 1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            &:after {
                content: "";
                background-color: rgba(0, 0, 0, 0.5);
                opacity: 1;
                transition: opacity 0.2s ease-in-out;
                @apply inset-0 absolute;
            }

            &.is-left {
                transform: scale(1)
                    translate3d(calc(var(--trading-card-width) * -0.6), 0, 0);
            }
            &.is-right {
                transform: scale(1)
                    translate3d(calc(var(--trading-card-width) * 0.6), 0, 0);
            }

            &.active {
                z-index: 2;
                transform: scale(1.2) translate3d(0, 0, 0);
                &::after {
                    opacity: 0;
                }
            }
        }
    }

    #recentCardsCarousel {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari and Opera */
        }

        .card-border {
            width: 110%;
            border: 1px solid var(--color-primary);
            border-radius: 5px;
            opacity: 90%;
            box-shadow: 1px 1px 6px var(--color-primary);
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 1.5rem;
            justify-content: flex-end;
            height: 420px;

            @media screen and (max-width: 768px) {
                height: 280px;
            }
        }

        .card-name {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;

            span {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }

    #mostPopularCarousel {
        img {
            width: 80%;
            margin: auto;
            display: none;

            &.active {
                display: block;
            }
        }
    }

    .PlayerCard--container {
        transition: all 0.3s ease-in-out;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        will-change: transform;
        filter: blur(0px);
        -webkit-font-smoothing: subpixel-antialiased;
        transform-origin: 0% 0;
        position: relative;

        .StashCheckbox {
            display: flex;
        }

        &:hover {
            transform: translate3d(0, -0.25rem, 0);

            .StashCheckbox {
                display: flex;
            }
        }
    }

    #filter-mobile-menu {
        top: 90px;
        bottom: 90px;
        // height: 75vh;
        // overflow-y: scroll;

        @media only screen and (max-width: 1024px) {
            position: absolute;
            top: 0;
            margin-left: -18px;
            z-index: 99;
            height: 100%;
            width: 85%;
            max-width: 350px;
        }
    }

    #filter-overlay-menu {
        top: 90px;
        bottom: 90px;
        height: 92vh;
        overflow-y: scroll;

        @media only screen and (max-width: 1024px) {
            position: absolute;
            top: 0;
            margin-left: -18px;
            z-index: 99;
            height: 100%;
            width: 85%;
            max-width: 350px;
        }
    }

    .Badge {
        position: relative;
        margin-left: 1rem;
        margin-right: 1rem;
        z-index: 2;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            z-index: 0;
            width: 1.15rem;
            height: 1.15rem;
            z-index: -1;
        }

        &::after {
            background-color: var(--color-primary);
            border-right: 1px solid var(--color-primary);
            border-top: 1px solid var(--color-primary);
            transform: rotate(45deg);
            right: -7px;
        }

        &::before {
            background-color: var(--color-primary);
            border-left: 1px solid var(--color-primary);
            border-bottom: 1px solid var(--color-primary);
            transform: rotate(45deg);
            left: -7px;
        }

        &.not-vaulted {
            &::before,
            &::after {
                background-color: transparent !important;
            }
        }
    }

    table {
        border-spacing: 0;
    }

    tbody td {
        @apply px-3 py-4;
    }

    .prose {
        h1,
        h2,
        h3,
        h4,
        h5,
        ol {
            counter-reset: item;
        }

        ol > li {
            counter-increment: item;
        }

        ol ol > li {
            display: block;
        }

        ol ol > li:before {
            content: counters(item, ".") ". ";
            margin-left: -20px;
        }
        a {
            color: var(--color-primary) !important;
        }
        // ol > li::before {
        //     color: currentColor !important;
        // }

        ol.list-lower-roman {
            list-style-type: lower-roman;
            counter-reset: list;

            li {
                counter-increment: list;
                @apply pl-2;
            }

            li::before {
                content: counter(list, lower-roman) ". ";
            }
        }
        ol.list-lower-alpha {
            list-style-type: lower-alpha;
            counter-reset: list;

            li {
                counter-increment: list;
                @apply pl-2;
            }

            li::before {
                content: "(" counter(list, lower-alpha) ")";
            }
        }
    }

    .TopBarLink {
        @apply py-4 lg:py-8 px-0 lg:px-4 text-gray-100 hover:text-white hover:border-primary;

        &.isActive {
            @apply border-b lg:border-b-2 border-primary text-white font-semibold;
        }
    }

    .divider {
        line-height: 1em;
        position: relative;
        outline: 0;
        border: 0;
        color: black;
        text-align: center;
        height: 1.5em;
        opacity: 0.5;
        &:before {
            content: "";
            //   background: linear-gradient(to right, transparent, #818078, transparent);
            background: #818078;
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 2px;
        }
        &:after {
            content: attr(data-content);
            position: relative;
            display: inline-block;
            color: black;
            padding: 0 0.5em;
            line-height: 1.5em;
            color: #818078;
            background-color: #fcfcfa;
        }
    }
    #recentCardsLinks {
        ul {
            li {
                &.active {
                    color: var(--color-primary);
                }
            }
        }
    }

    input[type="checkbox"].published + label {
        @apply text-white border-white select-none cursor-pointer;
    }

    input[type="checkbox"].published:checked + label {
        @apply text-primary border-primary;
    }
}
.ReactSelect__control.ReactSelect__control--is-focused {
    @apply ring-primary border-primary  hover:border-primary hover:ring-primary shadow-none;
}
.ReactSelect__control {
    @apply hover:border-primary hover:shadow-none py-1 rounded;
    border-width: 2px !important;
    border-radius: 0.375rem !important;
}
.ReactSelect__input input {
    @apply focus:ring-0;
}

.ReactSelect__option.ReactSelect__option--is-focused {
    @apply hover:bg-primary hover:bg-opacity-50 bg-primary bg-opacity-50;
}

.ReactSelect__option.ReactSelect__option--is-selected {
    @apply bg-primary text-white;
}

.img-cover-basic {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.hover-primary-btn {
    &:hover {
        background: transparent
            linear-gradient(180deg, #daa20f 0%, #ffcf52 100%) 0% 0% no-repeat
            padding-box;
    }
}

.pricing-sticky-nav {
    @apply sticky;
    top: 0;
    @media only screen and (min-width: 1024px) {
        top: 76px;
    }

    @media only screen and (min-width: 1201px) {
        top: 5.25rem;
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#mobile-menu.show {
    display: flex;
    @media screen and (min-width: 769px) {
        display: none;
    }
}

.merqary-list-container {
    margin: 0;
    max-height: 68.5vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    // Chrome, Safari and Opera
    &::-webkit-scrollbar {
        display: none;
    }

    .scroll-button {
        position: absolute;
        width: 3rem;
        height: 3rem;
        background: transparent;
        border: 2px solid rgba($color: #555555, $alpha: 0.4);
        border-radius: 50%;
        margin: auto;
        left: 0;
        right: 0;
        align-items: center;
        z-index: 100;
        color: #8d8d8d;
        box-shadow: 0 10px 25px -5px rgba($color: #434343, $alpha: 0.5);
        display: flex;
        justify-content: center;
        cursor: pointer;

        &.top {
            top: 0;
            margin-top: 4rem;
        }

        &.bottom {
            bottom: 0;
            margin-bottom: 1rem;
        }
    }
}

.merqary-accordion {
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    transition: 0.4s;
}

.merqary-arrow {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("icon-gray-up-keyboard.svg");
}

.merqary-arrow.down {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("icon-gray-down-keyboard.svg");
}

.merqaryAccordionBtn {
    display: flex;
    align-items: center;

    &::after,
    &.active::after {
        content: "";
        background-size: 100% 100%;
        height: 2rem;
        width: 2rem;
    }

    &::after {
        background-image: url("icon-gray-down-keyboard.svg");
        margin-right: -2rem;
    }

    &.active::after {
        background-image: url("icon-gray-up-keyboard.svg");
        margin-right: -2rem;
    }

    &.accordionReversed {
        &::after,
        &.active::after {
            display: none;
        }

        &::before,
        &.active::before {
            content: "";
            background-size: 100% 100%;
            height: 2rem;
            width: 2rem;
        }

        &::before {
            background-image: url("icon-gray-down-keyboard.svg");
        }

        &.active::before {
            background-image: url("icon-gray-up-keyboard.svg");
        }
    }
}

// .active, .merqary-accordion:hover {

// }

.merqary-panel {
    display: none;
    overflow: hidden;
}

.merqary-container {
    max-width: 1280px;
}

.merqary-container-sm {
    max-width: 1100px;
}

.merqary-main-market-header {
    @media screen and(min-width: 1024px) {
        margin-left: 340px;
    }
}

.merqary-tooltip {
    position: relative;
    display: inline-block;
    font-size: 10px;
}

.merqary-checkbox {
    background: #434343 0% 0% no-repeat padding-box;
    border: 1px solid #7b7b7b;
    border-radius: 3px;
}

.merqary-bg-filter {
    background: transparent linear-gradient(0deg, #000000 0%, #161616 100%) 0%
        0% no-repeat padding-box;
}

select.merqary-select {
    color: #cccccc;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background: linear-gradient(45deg, transparent 50%, #cccccc 50%),
        linear-gradient(135deg, #cccccc 50%, transparent 50%),
        linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 18px) calc(1em + 2px),
        calc(100% - 12px) calc(1em + 2px), 100% 0;
    background-size: 6px 6px, 6px 6px, 2.5em 2.5em;
    background-repeat: no-repeat;
}

select.merqary-select:focus {
    @media screen and (max-width: 769px) {
        box-shadow: none;
    }
}

.merqary-tooltip .merqary-tooltip-image,
.merqary-tooltip .merqary-tooltip-image-lg {
    visibility: hidden;
    width: 50px;
    background-color: #ffdd86;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 2px;

    /* Position the tooltip */
    position: absolute;
    width: 50px;
    bottom: 100%;
    left: 50%;
    margin-left: -20px;
    margin-bottom: 10px;
    z-index: 1;

    &.p-right {
        transform: translateY(60%);
        margin-left: 20px;
    }
}

.merqary-tooltip .merqary-tooltip-image-lg {
    width: 200px;
    padding: 10px;

    /* Position the tooltip */
    margin-left: -100px;
}

.merqary-tooltip:hover .merqary-tooltip-image,
.merqary-tooltip:hover .merqary-tooltip-image-lg {
    visibility: visible;
}

.merqary-banner {
    background: linear-gradient(
        -45deg,
        rgba(181, 136, 20, 1) 0%,
        rgba(255, 221, 134, 1) 20%,
        rgba(218, 162, 15, 1) 40%,
        rgba(255, 207, 82, 1) 60%,
        rgba(218, 162, 15, 1) 80%,
        rgba(181, 136, 20, 1) 100%
    );
    // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: banner_gradient 15s ease infinite;
}

.merqary-beta-banner {
    background: linear-gradient(
        -45deg,
        #8d8d8d 0%,
        #c0c0c0 20%,
        #d6d6d6 40%,
        #bbbbbb 60%,
        #d5d5d5 80%,
        #737373 100%
    );
    // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: banner_gradient 15s ease infinite;
}

@keyframes banner_gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.merqary-truncate {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
}

.merqary-truncate.two-lines {
    -webkit-line-clamp: 2;
}

.bento-menu {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    height: 20px;
    width: 20px;
}

.bento-dot {
    width: 4px;
    height: 4px;
    overflow: hidden;
}

.profilebox ~ .profileboxmenu,
.sellcenterbox ~ .sellcenterboxmenu {
    display: none;
}

.profilebox:checked ~ .profileboxmenu,
.sellcenterbox:checked ~ .sellcenterboxmenu {
    display: block;
    opacity: 1;
}

.merqary-order-table td,
.merqary-order-table th {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.merqary-order-table th {
    padding-top: 0rem;
    text-align: left;
}

.merqary-order-table-no-header td {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.merqary-table th,
.merqary-table td {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.merqary-input-icons img {
    position: absolute;
}

.merqary-icon {
    height: 20px;
    width: 20px;
    margin-left: 6px;
    margin-top: 6px;
}

.merqary-input-field {
    width: 100%;
    padding-left: 32px;
}
.merqary-cutoff {
    display: block; /* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 5.6em;
    line-height: 1.5em;
    width: 80%;

    @media screen and (max-width: 1028px) {
        width: 50%;
    }

    @media screen and (max-width: 728px) {
        width: 100%;
    }
}

.icon-vaulted {
    position: relative;
    width: 5em;
    height: 1.5em;
    background-color: var(--color-primary);
    text-align: center;
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    font-size: 0.8em;

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 0;
        border-top: 0.75em solid transparent;
        border-bottom: 0.75em solid transparent;
    }

    &::before {
        top: 0;
        left: 100%;
        border-left: 0.75em solid var(--color-primary);
    }

    &::after {
        right: 100%;
        top: 0;
        width: 0;
        border-right: 0.75em solid var(--color-primary);
    }
}

.merqary-vaulted-cards {
    @media screen and (max-width: 728px) {
        width: 30%;
    }
}

.date-picker-container {
    display: flex;
    align-items: center;
    padding-left: 1rem;

    .date-picker {
        border: none;
        box-sizing: border-box;
        outline: 0;
        padding: 0.75rem 0;
        position: relative;
        width: 100%;
        color: inherit;
        background-color: transparent;
        text-align: center;

        &:focus {
            outline: none !important;
            // border:1px solid rgba(#C2C2C2, .4);
            // box-shadow: 0 0 10px #719ECE;
            --tw-ring-color: none;
        }

        &::-webkit-datetime-edit-day-field:focus,
        &::-webkit-datetime-edit-month-field:focus,
        &::-webkit-datetime-edit-year-field:focus {
            background-color: transparent;
            color: inherit;
            outline: none;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
            // display: none;
            // -webkit-appearance: none;
            background: transparent;
            bottom: 0;
            color: transparent;
            cursor: pointer;
            height: auto;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
        }
    }
}

.no-spin-button {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
}

.placeholder-color-inherit {
    &::placeholder {
        color: inherit;
    }
}

.capitalize-first-letter {
    display: inline-block;
    &::first-letter {
        text-transform: uppercase;
    }
}

.account-sidebar {
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    box-shadow: 2px 2px 0 0 rgba(0,0,0,0.2);
    &.is-hidden {
        @media screen and (max-width: 767px) {
            transform: translate3d(-20rem, 0, 0);
            & + .account-backdrop {
                display: none;
            }
        }
    }

    @media screen and (max-width: 767px) {
        z-index: 10 !important;
        & + .account-backdrop {
            display: block;
        }
    }
}

.account-backdrop {
    display: none;
    position: fixed;
    background-color: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
}

.account-trigger {
    opacity: 0.7;
    bottom: 50px;
    transition: opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    &:hover {
        opacity: 1;
    }
}

body.sidebar-visible {
    @media screen and (max-width: 767px) {
        overflow: hidden;
    }
}