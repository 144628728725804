@font-face {
    font-family: 'Geomanist';
    src: url('../../javascript/fonts/geomanist-bold-webfont.eot');
    src: url('../../javascript/fonts/geomanist-bold-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('../../javascript/fonts/geomanist-bold-webfont.woff2')
            format('woff2'),
        url('../../javascript/fonts/geomanist-bold-webfont.woff') format('woff'),
        url('../../javascript/fonts/geomanist-bold-webfont.ttf')
            format('truetype'),
        url('../../javascript/fonts/geomanist-bold-webfont.svg#geomanistregular')
            format('svg');
    font-weight: 700;
    font-style: 700;
}

@font-face {
    font-family: 'Geomanist';
    src: url('../../javascript/fonts/geomanist-regular-webfont.eot');
    src: url('../../javascript/fonts/geomanist-regular-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('../../javascript/fonts/geomanist-regular-webfont.woff2')
            format('woff2'),
        url('../../javascript/fonts/geomanist-regular-webfont.woff')
            format('woff'),
        url('../../javascript/fonts/geomanist-regular-webfont.ttf')
            format('truetype'),
        url('../../javascript/fonts/geomanist-regular-webfont.svg#geomanistregular')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Geomanist';
    src: url('../../javascript/fonts/geomanist-medium-webfont.eot');
    src: url('../../javascript/fonts/geomanist-medium-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('../../javascript/fonts/geomanist-medium-webfont.woff2')
            format('woff2'),
        url('../../javascript/fonts/geomanist-medium-webfont.woff')
            format('woff'),
        url('../../javascript/fonts/geomanist-medium-webfont.ttf')
            format('truetype'),
        url('../../javascript/fonts/geomanist-medium-webfont.svg#geomanistregular')
            format('svg');
    font-weight: 600;
    font-style: 600;
}
